.container-center {
    text-align: center;
    width: auto;
    margin-left: 0em;
    margin-right: 0em;
    margin-top: 0.25rem;
}

.box {
    /*position: absolute;*/
    width: 36em;
    height: 25em;
    /*left: 686px;*/
    /*top: 345px;*/
    padding: 2em;
    margin: auto;

    background: rgba(120, 119, 119, 0.5);
    border-radius: 32px;
}

@media(min-width: 426px) {
    .box {
        padding: 2em 4.5em;
    }
}

.form-label {
    color: aliceblue;
    font-size: 1.3rem;
    text-align: center;
}

.submit-button {
    width: 100%;
    margin-top: 1em;
    align-content: center;
}

.gdot {
    height: 25px;
    width: 25px;
    background-color: #bbb;
    border-radius: 50%;
    align-content: center;
    margin: 0;
    padding: 0;
    /*display: inline-block;*/
}