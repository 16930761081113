.container-page {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

.row-main {
    width: 100%;
    height: 100vh;
    padding: 0;
    margin: 0;
}

.user {
    padding: 1.5em;
    background: rgba(40, 63, 72, 0.9);
}

.user-icon {
    border-radius: 50%;
    width: 3.5em;
    height: 3.5em;
    background: red;
    display: block;
}

.user-name {
    /* H6 - med 20 (30px, 0.15px) */

    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    /* or 150% */

    letter-spacing: 0.15px;

    /* Dark / White extra */

    color: #AFBDD1;

    margin: auto;
    text-align: left;
}

.station-list {
    width: 100%;
    /*height: 100%;*/
    left: 0px;
    top: 105px;

    background: linear-gradient(180deg, #EDEDED 0%, rgba(241, 241, 241, 0.23) 100%);
    border-radius: 9px;
    border-bottom: 2px solid rgba(76, 76, 76, 0.2);
    padding: 1em;
}

.station-title {
    /*background: linear-gradient(0deg, #2196F3, #2196F3);*/
    background: #6666FF;
    border-radius: 12px;
    max-width: 20em;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    margin: auto;
    padding: 0.8em 3em;
}

.station-title-text {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 21px;
    /* or 95% */

    display: flex;
    text-align: center;
    letter-spacing: 0.1px;

    color: #FFFFFF;
    margin: auto;
}

.station-item-text {
    font-size: 1.2em;
}

.add-station {
    /*background: linear-gradient(0deg, #2196F3, #2196F3);*/
    border-radius: 8px;
    max-width: 20em;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    margin: auto;
    padding: 0.2em 3em 1.2em 3em;
}

.add-station-title-text {
    font-style: normal;
    font-size: 22px;
    line-height: 21px;
    font-weight: inherit;
    /* or 95% */

    display: flex;
    text-align: center;
    letter-spacing: 0.1px;

    color: rgba(9, 0, 0, 0.84);
    margin: auto;
}

.form-label {
    font-size: 18px;
}

.main-left {
    overflow: scroll;
    max-width: 25%;
}

.main-right {
    border: 2px solid #000000;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 0;
    display: block;
    max-width: 75%;
}

.station-name {
    padding: 0;
    margin: auto;
}

.add-station-button {
    display: block;
}

.icon-style {
    font-size: 25px;
}

.btn-jump-point {
    display: block;
}

.btn-management {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin: auto;
    padding:0.5em 1em;
    border-radius: 0.8em;
    width: 10em;
}

.title-wrapper {
    padding: 3em;
}

.btn-management-wrapper {

}

@media (max-width: 768px) {
    .main-right {
        display: none;
    }
    .main-left {
        max-width: 100%;
        padding: 0;
    }
    .user {
        padding: 0.5em;
    }
    /*.add-station-button {*/
    /*    display: none;*/
    /*}*/
    /*.btn-jump-point {*/
    /*    display: none;*/
    /*}*/
    .title-wrapper {
        padding: 1em 1em 2em;
    }
    .btn-management-wrapper {
        display: none;
    }
}

@media (max-width: 375px) {
    .station-title {
        padding: 0.8em;
    }
    .station-name {
        font-size: medium;
    }
    .icon-style {
        font-size: 20px;
    }
    .btn-outline-secondary {
        padding: 0.25em 0.5em;
    }
    .btn-management {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        margin: auto;
        padding: 0.5em 0.6em;
        border-radius: 0.8em;
    }
    .station-title-text {
        font-size: medium;
        font-weight: bold;
    }
    .user-name {
        font-size: medium;
    }
    .col-3 {
        padding: 0;
    }
    .col-7 {
        padding: 0em 1.5em;
    }
}